import { useEffect, useState } from "react";
import { AxiosRequestConfig, AxiosError } from "axios";
import axios from "axios";

export interface IApiResponse<T, P> {
  params: P | null;
  data: T | null;
  pending: boolean;
  complete: boolean;
  error: any | null;
}

type ApiFuntion<P = any> = <C = P>(P?: C) => void;
export type ApiHookResponse<T, P = any> = [IApiResponse<T, P>, ApiFuntion<P>];
/**
 * useApiRequest -
 *
 * @template D - returned data
 * @template P - post data
 * @param {(params: P) => Promise<D>} asyncApiRequest
 * @param {P} params
 * @returns
 */
const useAxios = <D, P = any>(
  fn: (params?: any) => AxiosRequestConfig
): ApiHookResponse<D, P> => {
  const [res, setRes] = useState<IApiResponse<D, P>>({
    params: null,
    data: null,
    pending: false,
    complete: false,
    error: null
  });

  const [req, setReq] = useState<{
    config: AxiosRequestConfig;
    params: P | any;
  } | null>(null);

  useEffect(() => {
    if (!req) return;

    setRes({
      params: req.params,
      data: null,
      pending: true,
      error: null,
      complete: false
    });

    if (req.config.headers === false) {
      axios(req.config)
        .then(response => {
          console.log(
            `${req.config.method} ${name} RESPONSE %o`,
            response.data
          );
          setRes({
            params: req.params,
            data: response.data.data,
            pending: false,
            error: null,
            complete: true
          });
        })
        .catch((error: AxiosError) => {
          console.error(`${req.config.method} ${name} %`, error.response);

          setRes({
            params: req.params,
            data: null,
            pending: false,
            error: error,
            complete: true
          });
        });
    } else {
      console.log(`${req.config.method} ${name} %o`, req.params, {
        ...req.config
      });
      axios({ ...req.config })
        .then(response => {
          console.log(
            `${req.config.method} ${name} RESPONSE %o`,
            response.data
          );
          setRes({
            params: req.params,
            data: response.data,
            pending: false,
            error: null,
            complete: true
          });
        })
        .catch((error: AxiosError) => {
          console.error(`${req.config.method} ${name} %o`, error.response);

          setRes({
            params: req.params,
            data: null,
            pending: false,
            error: error,
            complete: true
          });
        });
    }
  }, [req]);

  return [
    res,
    (params?: any) => setReq({ config: fn(params), params: params })
  ];
};

export default useAxios;
